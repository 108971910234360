<template>
  <div
    class="d-flex flex-wrap justify-center align-center text-h5 error--text font-weight-bold"
    style="height: 80vh"
  >
    <div>
      <div class="text-center mb-2">
        <v-icon
          color="error"
          size="35"
        >
          mdi-close-circle
        </v-icon>
      </div>
      <div style="letter-spacing: 2px">
        {{ $t('The page you requested was not found') }}
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
</style>